import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Card, Spinner, Alert, Button } from 'react-bootstrap';
import { BsImage } from 'react-icons/bs';
import { useCart } from '../contexts/CartContext';

function ProductDetail() {
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    if (product) {
      addToCart(product);
    }
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`/api/products/${id}`);
        setProduct(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching product:', error);
        setError('Failed to fetch product details. Please try again later.');
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  if (loading) {
    return <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!product) {
    return <Alert variant="info">Product not found.</Alert>;
  }

  return (
    <div>
      <h1 className="mb-4">{product.name}</h1>
      <Card>
        {product.image_url ? (
          <Card.Img 
            variant="top" 
            src={product.image_url} 
            alt={product.name}
            style={{ height: '400px', objectFit: 'cover' }}
          />
        ) : (
          <div className="text-center p-4 bg-light" style={{ height: '400px' }}>
            <BsImage size={100} className="text-secondary mt-5" />
            <p className="mt-3 text-secondary">No Image Available</p>
          </div>
        )}
        <Card.Body>
          <Card.Title as="h2">{product.name}</Card.Title>
          <Card.Text as="h3" className="text-primary">${product.price.toFixed(2)}</Card.Text>
          <Card.Text><strong>Pages:</strong> {product.pages}</Card.Text>
          <Card.Text><strong>Size:</strong> {product.size}</Card.Text>
          <Card.Text><strong>Paper:</strong> {product.paper}</Card.Text>
          <Card.Text><strong>Cover:</strong> {product.cover}</Card.Text>
          <Card.Text><strong>Suitable Media:</strong> {product.suitable_media}</Card.Text>
          <Card.Text><strong>Binding Type:</strong> {product.binding_type}</Card.Text>
          <Card.Text><strong>Perfect For:</strong> {product.perfect_for}</Card.Text>
          <Card.Text><strong>Additional Features:</strong> {product.additional_features}</Card.Text>
          <Button 
            variant="primary" 
            onClick={handleAddToCart}
            className="mt-3"
          >
            Add to Cart
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ProductDetail;
