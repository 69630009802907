import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Card, Alert, Spinner, Button } from "react-bootstrap";
import { BsImage } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useCart } from "../contexts/CartContext";

function Home() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { addToCart } = useCart();

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleAddToCart = (product) => {
    addToCart(product);
  };

  const fetchProducts = () => {
    setLoading(true);
    setError(null);
    axios
      .get("/api/products")
      .then((response) => {
        setProducts(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        setError("Failed to fetch products. Please try again later.");
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <div>
      {/* <h1 className="mb-4">Welcome to our Faerie Products Store</h1> */}
      {products.length === 0 ? (
        <Alert variant="info">No products available at the moment.</Alert>
      ) : (
        <Row>
          {products.map((product) => (
            <Col key={product.id} xs={12} md={6} lg={4} className="mb-4">
              <Card
                as={Link}
                to={`/product/${product.id}`}
                className="text-decoration-none text-dark h-100"
              >
                {product.image_url ? (
                  <Card.Img
                    variant="top"
                    src={product.image_url}
                    alt={product.name}
                    style={{ height: "200px", objectFit: "cover" }}
                  />
                ) : (
                  <div className="text-center p-4 bg-light">
                    <BsImage size={50} className="text-secondary" />
                    <p className="mt-2 text-secondary">No Image</p>
                  </div>
                )}
                <Card.Body>
                  <Card.Title>{product.name}</Card.Title>
                  <Card.Text>
                    <strong>Price: ${product.price.toFixed(2)}</strong>
                  </Card.Text>
                  <Card.Text>Pages: {product.pages}</Card.Text>
                  <Card.Text>Size: {product.size}</Card.Text>
                  <Button 
                    variant="primary" 
                    onClick={(e) => {
                      e.preventDefault();
                      handleAddToCart(product);
                    }}
                  >
                    Add to Cart
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
}

export default Home;
