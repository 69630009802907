import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Alert, Table } from 'react-bootstrap';
import { useCart } from '../contexts/CartContext';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

function Checkout() {
  const { cart, getTotalPrice, clearCart } = useCart();
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/completion`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  if (isSubmitted) {
    return (
      <Container>
        <Alert variant="success">
          Thank you for your order! We've received your payment and will process your order soon.
        </Alert>
      </Container>
    );
  }

  return (
    <Container>
      <h1>Checkout</h1>
      <h2>Order Summary</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Product</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {cart.map((item) => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td>{item.quantity}</td>
              <td>${item.price.toFixed(2)}</td>
              <td>${(item.price * item.quantity).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="3" className="text-right"><strong>Total:</strong></td>
            <td><strong>${getTotalPrice().toFixed(2)}</strong></td>
          </tr>
        </tfoot>
      </Table>
      <h2>Payment Information</h2>
      <Form onSubmit={handleSubmit}>
        <PaymentElement />
        <Button variant="primary" type="submit" disabled={isLoading || !stripe || !elements}>
          {isLoading ? 'Processing...' : 'Pay Now'}
        </Button>
        {message && <div>{message}</div>}
      </Form>
    </Container>
  );
}

export default Checkout;
