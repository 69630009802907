import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Home from "./components/Home";
import AdminForm from "./components/AdminForm";
import About from "./components/About";
import ContactUs from "./components/ContactUs";
import ProductDetail from "./components/ProductDetail";
import Footer from "./components/Footer";
import Cart from "./components/Cart";
import Checkout from "./components/Checkout";
import PaymentCompletion from "./components/PaymentCompletion";
import { CartProvider } from "./contexts/CartContext";

// Make sure to call loadStripe outside of a component's render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');

function App() {
  return (
    <CartProvider>
      <Elements stripe={stripePromise}>
        <Router>
          <div className="App">
            <Navbar bg="light" expand="lg">
              <Container>
                <Navbar.Brand as={Link} to="/">
                  Faerie Made
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <Nav.Link as={Link} to="/">
                      Home
                    </Nav.Link>
                    {/* <Nav.Link as={Link} to="/admin">
                      Admin
                    </Nav.Link> */}
                    <Nav.Link as={Link} to="/about">
                      About
                    </Nav.Link>
                    <Nav.Link as={Link} to="/contact">
                      Contact Us
                    </Nav.Link>
                    <Nav.Link as={Link} to="/cart">
                      Cart
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>

        <Container className="mt-4">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/product/:id" element={<ProductDetail />} />
            <Route path="/admin" element={<AdminForm />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/completion" element={<PaymentCompletion />} />
          </Routes>
        </Container>
        <Footer />
      </div>
    </Router>
    </Elements>
    </CartProvider>
  );
}

export default App;
