import React from "react";

function About() {
  return (
    <div>
      <h1 className="mb-4">About Us</h1>
      <p>
        Fairie is the realm of imagination. Everything that comes from faerie
        brings beauty, wonder and joy. I combine paper, fabric, leather, thread
        and yarn to make unique handmade products that are inspired by nature
        and created for your enchantment.
      </p>
      <p>
        Products include handmade sketchbooks, mixed media journals,
        hand-printed paper, hand embroidered home decor items, and mixed media
        art.
      </p>
      <p>
        We love to hear from our customers. Go to the Contact Us page and send
        us a message.
      </p>
      <p>
        Please follow us on Instagram. Our Etsy shop is going to be up soon.{" "}
      </p>
    </div>
  );
}

export default About;
