import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form, Alert, ProgressBar } from 'react-bootstrap';

function AdminForm() {
  const [products, setProducts] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [product, setProduct] = useState({
    id: '',
    name: '',
    pages: '',
    size: '',
    paper: '',
    cover: '',
    suitable_media: '',
    binding_type: '',
    perfect_for: '',
    price: '',
    additional_features: '',
    image_url: ''
  });
  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get('/api/products');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct(prevProduct => ({
      ...prevProduct,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/products', {
        ...product,
        pages: parseInt(product.pages),
        price: parseFloat(product.price)
      });
      setMessage('Product added successfully');
      setShowAddModal(false);
      fetchProducts();
      resetForm();
    } catch (error) {
      setMessage('Error adding product');
      console.error('Error adding product:', error);
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`/api/products/${product.id}`, {
        ...product,
        pages: parseInt(product.pages),
        price: parseFloat(product.price)
      });
      setMessage('Product updated successfully');
      setShowEditModal(false);
      fetchProducts();
      resetForm();
    } catch (error) {
      setMessage('Error updating product');
      console.error('Error updating product:', error);
    }
  };

  const handleImportCSV = async (e) => {
    e.preventDefault();
    if (!file) {
      setMessage('Please select a CSV file');
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    setIsUploading(true);
    setUploadProgress(0);
    try {
      await axios.post('/api/import-csv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });
      setMessage('CSV imported successfully');
      setShowImportModal(false);
      fetchProducts();
      setFile(null);
    } catch (error) {
      setMessage('Error importing CSV: ' + (error.response?.data?.error || error.message));
      console.error('Error importing CSV:', error);
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  const resetForm = () => {
    setProduct({
      id: '',
      name: '',
      pages: '',
      size: '',
      paper: '',
      cover: '',
      suitable_media: '',
      binding_type: '',
      perfect_for: '',
      price: '',
      additional_features: '',
      image_url: ''
    });
  };

  const openEditModal = (prod) => {
    setProduct(prod);
    setShowEditModal(true);
  };

  return (
    <div>
      <h1 className="mb-4">Admin: Product Management</h1>
      <Button variant="primary" onClick={() => setShowAddModal(true)} className="me-2">
        Add Product
      </Button>
      <Button variant="secondary" onClick={() => setShowImportModal(true)}>
        Import CSV
      </Button>
      
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map((prod) => (
            <tr key={prod.id}>
              <td>{prod.name}</td>
              <td>${prod.price}</td>
              <td>
                <Button variant="info" size="sm" onClick={() => openEditModal(prod)}>
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {message && <Alert variant={message.includes('Error') ? 'danger' : 'success'}>{message}</Alert>}
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" value={product.name} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Number of Pages</Form.Label>
              <Form.Control type="number" name="pages" value={product.pages} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Size</Form.Label>
              <Form.Control type="text" name="size" value={product.size} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Paper</Form.Label>
              <Form.Control type="text" name="paper" value={product.paper} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Cover</Form.Label>
              <Form.Control type="text" name="cover" value={product.cover} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Suitable Media</Form.Label>
              <Form.Control type="text" name="suitable_media" value={product.suitable_media} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Binding Type</Form.Label>
              <Form.Control type="text" name="binding_type" value={product.binding_type} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Perfect For</Form.Label>
              <Form.Control type="text" name="perfect_for" value={product.perfect_for} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Price</Form.Label>
              <Form.Control type="number" step="0.01" name="price" value={product.price} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Additional Features</Form.Label>
              <Form.Control as="textarea" rows={3} name="additional_features" value={product.additional_features} onChange={handleChange} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Image URL</Form.Label>
              <Form.Control type="text" name="image_url" value={product.image_url} onChange={handleChange} />
            </Form.Group>
            <Button variant="primary" type="submit">
              Add Product
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEdit}>
            {message && <Alert variant={message.includes('Error') ? 'danger' : 'success'}>{message}</Alert>}
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" value={product.name} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Number of Pages</Form.Label>
              <Form.Control type="number" name="pages" value={product.pages} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Size</Form.Label>
              <Form.Control type="text" name="size" value={product.size} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Paper</Form.Label>
              <Form.Control type="text" name="paper" value={product.paper} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Cover</Form.Label>
              <Form.Control type="text" name="cover" value={product.cover} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Suitable Media</Form.Label>
              <Form.Control type="text" name="suitable_media" value={product.suitable_media} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Binding Type</Form.Label>
              <Form.Control type="text" name="binding_type" value={product.binding_type} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Perfect For</Form.Label>
              <Form.Control type="text" name="perfect_for" value={product.perfect_for} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Price</Form.Label>
              <Form.Control type="number" step="0.01" name="price" value={product.price} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Additional Features</Form.Label>
              <Form.Control as="textarea" rows={3} name="additional_features" value={product.additional_features} onChange={handleChange} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Image URL</Form.Label>
              <Form.Control type="text" name="image_url" value={product.image_url} onChange={handleChange} />
            </Form.Group>
            <Button variant="primary" type="submit">
              Update Product
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showImportModal} onHide={() => setShowImportModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Import Products from CSV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleImportCSV}>
            {message && <Alert variant={message.includes('Error') ? 'danger' : 'success'}>{message}</Alert>}
            <Form.Group className="mb-3">
              <Form.Label>Select CSV File</Form.Label>
              <Form.Control type="file" accept=".csv" onChange={handleFileChange} required />
            </Form.Group>
            {isUploading && (
              <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} className="mb-3" />
            )}
            <Button variant="primary" type="submit" disabled={isUploading}>
              {isUploading ? 'Uploading...' : 'Import CSV'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AdminForm;
